import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Input/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default /* @__PURE__ */ _defineComponent({
  __name: "Search",
  props: {
    sm: Boolean,
    showPrefix: Boolean,
    inputClasses: {
      type: String,
      default: ""
    }
  },
  setup(__props) {
    const props = __props;
    const inputClass = computed(() => {
      const result = [];
      if (props.showPrefix) {
        result.push("px-2");
      }
      result.push(props.inputClasses);
      return result.join(" ");
    });
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_AppInput = __nuxt_component_1;
      return _openBlock(), _createBlock(_component_AppInput, _mergeProps(_ctx.$attrs, {
        ref: "search-input",
        sm: __props.sm,
        "input-classes": _unref(inputClass)
      }), _createSlots({ _: 2 }, [
        __props.showPrefix ? {
          name: "prefix",
          fn: _withCtx(() => [
            _createVNode(_component_BaseIcon, {
              name: "search",
              class: _normalizeClass([__props.sm ? "w-4 h-4" : "w-5 h-5"])
            }, null, 8, ["class"])
          ]),
          key: "0"
        } : {
          name: "addon",
          fn: _withCtx(() => [
            _createVNode(_component_BaseIcon, {
              name: "search",
              class: _normalizeClass([__props.sm ? "w-4 h-4" : "w-5 h-5"])
            }, null, 8, ["class"])
          ]),
          key: "1"
        }
      ]), 1040, ["sm", "input-classes"]);
    };
  }
});
