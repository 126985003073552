"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["src", "name"];
import { getTokenLogoWithVendorPathPrefix } from "@injectivelabs/sdk-ui-ts";
export default /* @__PURE__ */ _defineComponent({
  __name: "TokenIcon",
  props: {
    sm: Boolean,
    lg: Boolean,
    xl: Boolean,
    token: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const logoPath = computed(
      () => getTokenLogoWithVendorPathPrefix(props.token.logo)
    );
    const sizeClasses = computed(() => {
      if (props.sm) {
        return "w-4 h-4 min-w-4";
      }
      if (props.lg) {
        return "w-8 h-8 min-w-8";
      }
      if (props.xl) {
        return "w-10 h-10 min-w-10";
      }
      return "w-6 h-6 min-w-6";
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("img", {
        class: _normalizeClass(["rounded-full", _unref(sizeClasses)]),
        src: _unref(logoPath),
        name: __props.token.name
      }, null, 10, _hoisted_1);
    };
  }
});
