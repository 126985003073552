"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default /* @__PURE__ */ _defineComponent({
  __name: "Header",
  props: {
    lg: Boolean,
    md: Boolean,
    classes: {
      type: String,
      default: "md:grid hidden"
    }
  },
  setup(__props) {
    const props = __props;
    const sizeClasses = computed(() => {
      const result = [];
      if (props.lg) {
        result.push("py-2", "px-6", "text-2xs", "2xl:text-xs");
      } else {
        result.push("px-3", "py-4", "text-2xs", "2xl:text-xs");
      }
      return result.join(" ");
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["grid-cols-12 gap-4 text-gray-200 uppercase items-center", [__props.classes, _unref(sizeClasses)]])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2);
    };
  }
});
