import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Markets/Card.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/HorizontalScrollView/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "w-full mx-auto xl:w-4/5 relative" };
const _hoisted_2 = { class: "bg-cover bg-center" };
const _hoisted_3 = { class: "mt-6" };
const _hoisted_4 = { class: "text-xl tracking-wider leading-6 font-bold hidden md:block" };
import { BigNumberInBase } from "@injectivelabs/utils";
import { newMarketsSlug } from "@/app/data/market";
export default /* @__PURE__ */ _defineComponent({
  __name: "Overview",
  props: {
    markets: {
      type: Array,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const marketsWithLastTradedPriceGreaterThanZero = computed(() => {
      return props.markets.filter(({ summary }) => {
        const lastTradedPrice = new BigNumberInBase(
          (summary == null ? void 0 : summary.lastPrice) || (summary == null ? void 0 : summary.price) || "0"
        );
        return lastTradedPrice.gt("0");
      });
    });
    const newMarket = computed(() => {
      const firstExistingNewMarket = newMarketsSlug.find((newMarketSlug) => {
        return props.markets.find(({ market: { slug } }) => {
          return slug.toLowerCase() === newMarketSlug.toLowerCase();
        });
      });
      if (!firstExistingNewMarket) {
        return;
      }
      return props.markets.find(({ market: { slug } }) => {
        return slug.toLowerCase() === firstExistingNewMarket.toLowerCase();
      });
    });
    const topVolume = computed(() => {
      if (props.markets.length === 0) {
        return void 0;
      }
      const market = props.markets.reduce(
        (initialMarket, market2) => {
          if (initialMarket.volumeInUsd.eq(market2.volumeInUsd)) {
            return sortMarketsAlphabetically(market2, initialMarket);
          }
          return initialMarket.volumeInUsd.gt(market2.volumeInUsd) ? initialMarket : market2;
        }
      );
      if (market.volumeInUsd.lte(0)) {
        return;
      }
      return market;
    });
    const topGainer = computed(() => {
      if (marketsWithLastTradedPriceGreaterThanZero.value.length === 0) {
        return void 0;
      }
      return marketsWithLastTradedPriceGreaterThanZero.value.reduce(
        (initialMarket, market) => {
          if (initialMarket.summary.change === market.summary.change) {
            return sortMarketsAlphabetically(market, initialMarket);
          }
          return initialMarket.summary.change > market.summary.change ? initialMarket : market;
        }
      );
    });
    function sortMarketsAlphabetically(market, initialMarket) {
      return initialMarket.market.slug.localeCompare(market.market.slug) > 0 ? market : initialMarket;
    }
    return (_ctx, _cache) => {
      const _component_PartialsMarketsCard = __nuxt_component_0;
      const _component_AppHorizontalScrollView = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t("markets.title")), 1),
            _createVNode(_component_AppHorizontalScrollView, { class: "mt-4" }, {
              default: _withCtx(() => [
                _unref(newMarket) && _unref(newMarket).summary ? (_openBlock(), _createBlock(_component_PartialsMarketsCard, {
                  key: 0,
                  class: "flex-0-full col-span-6 xl:col-span-4",
                  "data-cy": "market-card-whats-new",
                  market: _unref(newMarket).market,
                  summary: _unref(newMarket).summary,
                  "volume-in-usd": _unref(newMarket).volumeInUsd
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("markets.whatsNew")), 1)
                  ]),
                  _: 1
                }, 8, ["market", "summary", "volume-in-usd"])) : _createCommentVNode("", true),
                _unref(topVolume) && _unref(topVolume).summary ? (_openBlock(), _createBlock(_component_PartialsMarketsCard, {
                  key: 1,
                  class: "flex-0-full col-span-6 xl:col-span-4",
                  "data-cy": "market-card-top-volume",
                  market: _unref(topVolume).market,
                  summary: _unref(topVolume).summary,
                  "volume-in-usd": _unref(topVolume).volumeInUsd
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("markets.topVolume")), 1)
                  ]),
                  _: 1
                }, 8, ["market", "summary", "volume-in-usd"])) : _createCommentVNode("", true),
                _unref(topGainer) && _unref(topGainer).summary ? (_openBlock(), _createBlock(_component_PartialsMarketsCard, {
                  key: 2,
                  class: "flex-0-full col-span-6 xl:col-span-4",
                  "data-cy": "market-card-top-gainer",
                  market: _unref(topGainer).market,
                  summary: _unref(topGainer).summary,
                  "volume-in-usd": _unref(topGainer).volumeInUsd
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("markets.topGainer")), 1)
                  ]),
                  _: 1
                }, 8, ["market", "summary", "volume-in-usd"])) : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ])
        ])
      ]);
    };
  }
});
