"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["href"];
const _hoisted_2 = { class: "rounded bg-orange-[#fdba74] py-1 px-1 cursor-pointer flex items-center" };
const _hoisted_3 = { class: "text-orange-[#c2410c] text-3xs font-bold uppercase whitespace-nowrap" };
import { isWithinInterval } from "date-fns";
import { marketPromotions } from "@/app/data/market";
export default /* @__PURE__ */ _defineComponent({
  __name: "Airdrop",
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const promotion = computed(() => {
      return marketPromotions.find(
        (promotion2) => promotion2.market === props.market.slug
      );
    });
    const showPromotion = computed(() => {
      if (!props.market || !promotion.value) {
        return false;
      }
      return isWithinInterval(Date.now(), {
        start: promotion.value.start,
        end: promotion.value.end
      });
    });
    const url = computed(() => {
      if (!promotion.value) {
        return "";
      }
      return promotion.value.url;
    });
    return (_ctx, _cache) => {
      return _unref(showPromotion) ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: _unref(url),
        target: "_blank"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, " \u{1F525} " + _toDisplayString(_ctx.$t("markets.airdrop")), 1)
        ])
      ], 8, _hoisted_1)) : _createCommentVNode("", true);
    };
  }
});
