import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["data-cy"];
const _hoisted_2 = { class: "text-sm col-span-2 sm:col-span-3 flex items-center" };
const _hoisted_3 = { class: "cursor-pointer flex items-center" };
const _hoisted_4 = { class: "flex flex-col" };
const _hoisted_5 = {
  class: "tracking-wider font-bold mb-1",
  "data-cy": "markets-ticker-name-table-data"
};
const _hoisted_6 = { class: "text-gray-500 text-xs hidden md:block" };
const _hoisted_7 = { class: "sm:hidden flex flex-col items-end font-mono" };
const _hoisted_8 = { class: "flex flex-wrap items-center" };
const _hoisted_9 = { class: "w-full flex items-center" };
const _hoisted_10 = {
  key: 0,
  class: ""
};
const _hoisted_11 = { class: "font-sans" };
const _hoisted_12 = {
  key: 1,
  class: "text-gray-400"
};
const _hoisted_13 = { class: "w-full text-gray-500 text-xs" };
const _hoisted_14 = {
  class: "hidden font-mono sm:flex sm:flex-wrap items-center justify-end col-span-3",
  "data-cy": "markets-last-traded-price-table-data"
};
const _hoisted_15 = { class: "w-full flex items-center justify-end" };
const _hoisted_16 = { key: 0 };
const _hoisted_17 = { class: "font-sans" };
const _hoisted_18 = {
  key: 1,
  class: "text-gray-400"
};
const _hoisted_19 = { class: "w-full text-gray-500 text-xs text-right" };
import { BigNumberInWei } from "@injectivelabs/utils";
import {
  ZERO_IN_BASE,
  MarketType
} from "@injectivelabs/sdk-ui-ts";
import { format, fromUnixTime } from "date-fns";
import { getMarketRoute } from "@/app/utils/market";
import { UI_DEFAULT_PRICE_DISPLAY_DECIMALS } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "Row",
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    var _a;
    const props = __props;
    const settlementPrice = computed(() => {
      if (!props.market) {
        return ZERO_IN_BASE;
      }
      if (!props.market.expiryFuturesMarketInfo) {
        return ZERO_IN_BASE;
      }
      if (!props.market.expiryFuturesMarketInfo.settlementPrice) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInWei(
        props.market.expiryFuturesMarketInfo.settlementPrice
      ).toBase(props.market.quoteToken.decimals);
    });
    const { valueToString: settlementPriceToFormat } = useBigNumberFormatter(
      settlementPrice,
      {
        decimalPlaces: ((_a = props.market) == null ? void 0 : _a.priceDecimals) || UI_DEFAULT_PRICE_DISPLAY_DECIMALS
      }
    );
    const expiryAt = computed(() => {
      if (!props.market) {
        return "";
      }
      if (props.market.type === MarketType.Spot) {
        return "";
      }
      if (props.market.subType === MarketType.BinaryOptions) {
        return "";
      }
      if (props.market.subType === MarketType.Perpetual) {
        return "";
      }
      const derivativeMarket = props.market;
      const expiryFuturesMarketInfo = derivativeMarket.expiryFuturesMarketInfo;
      if (!expiryFuturesMarketInfo) {
        return "";
      }
      if (!expiryFuturesMarketInfo.expirationTimestamp) {
        return "";
      }
      return format(
        fromUnixTime(expiryFuturesMarketInfo.expirationTimestamp),
        "dd LLL yyyy, HH:mm:ss"
      );
    });
    const marketRoute = computed(() => {
      if (!props.market) {
        return void 0;
      }
      return getMarketRoute(props.market);
    });
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      const _component_NuxtLink = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", {
        class: "grid grid-cols-3 sm:grid-cols-10 3md:grid-cols-12 text-gray-200 gap-4 text-sm px-4 py-2 mb-1 items-center border-b",
        "data-cy": `markets-expired-table-row-${__props.market.ticker}`
      }, [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(_component_NuxtLink, {
            class: "cursor-pointer",
            to: _unref(marketRoute)
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                __props.market.baseToken ? (_openBlock(), _createBlock(_component_CommonTokenIcon, {
                  key: 0,
                  token: __props.market.baseToken,
                  class: "mr-3 hidden 3md:block"
                }, null, 8, ["token"])) : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(__props.market.ticker), 1),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(__props.market.baseToken.name), 1)
                ])
              ])
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              !_unref(settlementPrice).isNaN() ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("markets.settledAt")), 1),
                _createTextVNode(" " + _toDisplayString(_unref(settlementPriceToFormat)) + " " + _toDisplayString(__props.market.quoteToken.symbol), 1)
              ])) : (_openBlock(), _createElementBlock("span", _hoisted_12, "\u2014"))
            ]),
            _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(expiryAt)), 1)
          ])
        ]),
        _createElementVNode("span", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            !_unref(settlementPrice).isNaN() ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
              _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("markets.settledAt")), 1),
              _createTextVNode(" " + _toDisplayString(_unref(settlementPriceToFormat)) + " " + _toDisplayString(__props.market.quoteToken.symbol), 1)
            ])) : (_openBlock(), _createElementBlock("span", _hoisted_18, "\u2014"))
          ]),
          _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(expiryAt)), 1)
        ])
      ], 8, _hoisted_1);
    };
  }
});
