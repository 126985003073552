import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Markets/Filters/Index.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/SortableHeaderItem.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Common/Table/Header.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Markets/Row.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Common/EmptyList.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/Common/Table/Body.vue";
import { default as __nuxt_component_7 } from "/opt/build/repo/components/Partials/Markets/Expired/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "xl:max-w-6xl mx-auto py-6 md:py-12" };
const _hoisted_2 = { class: "text-xl tracking-wider leading-6 font-bold md:hidden mb-6" };
const _hoisted_3 = { class: "text-gray-200 text-2xs font-normal" };
const _hoisted_4 = { class: "text-gray-200 text-xs mr-1" };
const _hoisted_5 = { class: "text-gray-200 text-2xs font-normal" };
const _hoisted_6 = {
  class: "text-gray-200 text-2xs font-normal",
  "data-cy": "markets-market-table-header"
};
const _hoisted_7 = { class: "text-right col-span-2" };
const _hoisted_8 = { class: "text-gray-200 text-2xs font-normal" };
const _hoisted_9 = {
  class: "text-gray-200 text-2xs font-normal",
  "data-cy": "markets-change_24h-table-header"
};
const _hoisted_10 = {
  class: "text-gray-200 text-2xs font-normal",
  "data-cy": "markets-volume_24h-table-header"
};
const _hoisted_11 = /* @__PURE__ */ _createElementVNode("span", { class: "hidden 3md:block text-left col-span-2" }, null, -1);
const _hoisted_12 = {
  key: 0,
  class: "mt-2 text-xs text-gray-500"
};
const _hoisted_13 = {
  key: 1,
  class: "mt-2 text-xs text-gray-500"
};
const _hoisted_14 = {
  key: 0,
  class: "mt-12"
};
const _hoisted_15 = { class: "text-sm tracking-wider leading-6 mb-4" };
import { BigNumberInBase } from "@injectivelabs/utils";
import { MarketType } from "@injectivelabs/sdk-ui-ts";
import {
  MarketCategoryType,
  MarketQuoteType
} from "@/types";
import {
  marketIsPartOfCategory,
  marketIsQuotePair,
  marketIsPartOfType,
  marketIsPartOfSearch
} from "@/app/utils/market";
import { deprecatedMarkets, upcomingMarkets } from "@/app/data/market";
import { LOW_VOLUME_MARKET_THRESHOLD } from "@/app/utils/constants";
var MarketHeaderType = /* @__PURE__ */ ((MarketHeaderType2) => {
  MarketHeaderType2["Market"] = "market";
  MarketHeaderType2["Change"] = "change";
  MarketHeaderType2["Volume"] = "volume";
  return MarketHeaderType2;
})(MarketHeaderType || {});
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    markets: {
      type: Array,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const route = useRoute();
    const appStore = useAppStore();
    const derivativeStore = useDerivativeStore();
    const activeCategory = ref(MarketCategoryType.All);
    const activeQuote = ref(MarketQuoteType.All);
    const activeType = ref("");
    const search = ref("");
    const sortBy = ref("volume" /* Volume */);
    const ascending = ref(false);
    const showLowVolumeMarkets = ref(false);
    const recentlyExpiredMarkets = computed(
      () => derivativeStore.recentlyExpiredMarkets
    );
    const favoriteMarkets = computed(() => appStore.favoriteMarkets);
    const filteredMarkets = computed(() => {
      return props.markets.filter(({ market, volumeInUsd }) => {
        const isPartOfCategory = marketIsPartOfCategory(
          activeCategory.value,
          market
        );
        const isPartOfSearch = marketIsPartOfSearch(search.value, market);
        const isPartOfType = marketIsPartOfType({
          market,
          favoriteMarkets: favoriteMarkets.value,
          activeType: activeType.value
        });
        const isQuotePair = marketIsQuotePair(activeQuote.value, market);
        const isLowVolumeMarket = showLowVolumeMarkets.value || volumeInUsd.gte(LOW_VOLUME_MARKET_THRESHOLD);
        return isPartOfCategory && isPartOfType && isPartOfSearch && isQuotePair && isLowVolumeMarket;
      });
    });
    const sortedMarkets = computed(() => {
      const upcomingMarketsSlugs = upcomingMarkets.map(({ slug }) => slug);
      const deprecatedMarketsSlugs = deprecatedMarkets.map(({ slug }) => slug);
      if (sortBy.value.trim() === "") {
        return filteredMarkets.value;
      }
      const markets = [...filteredMarkets.value].sort(
        (m1, m2) => {
          if (upcomingMarketsSlugs.includes(m1.market.slug) || deprecatedMarketsSlugs.includes(m1.market.slug)) {
            return 1;
          }
          if (sortBy.value === "market" /* Market */) {
            return m2.market.ticker.localeCompare(m1.market.ticker);
          }
          if (sortBy.value === "change" /* Change */) {
            if (new BigNumberInBase(m2.summary.change).eq(m1.summary.change)) {
              return m1.market.ticker.localeCompare(m2.market.ticker);
            }
            return new BigNumberInBase(m2.summary.change).minus(m1.summary.change).toNumber();
          }
          if (new BigNumberInBase(m2.volumeInUsd).eq(m1.volumeInUsd)) {
            return m1.market.ticker.localeCompare(m2.market.ticker);
          }
          return m2.volumeInUsd.minus(m1.volumeInUsd).toNumber();
        }
      );
      return ascending.value ? markets.reverse() : markets;
    });
    onMounted(() => {
      prefillFromQueryParams();
    });
    function handleSort(value) {
      if (value !== sortBy.value) {
        sortBy.value = value;
      }
    }
    function handleAscending(value) {
      ascending.value = value;
    }
    function prefillFromQueryParams() {
      const { query } = route;
      const category = typeof query.category === "string" ? query.category.trim().toLowerCase() : query.category;
      const quote = typeof query.quote === "string" ? query.quote.trim().toLowerCase() : query.quote;
      const type = typeof query.type === "string" ? query.type.trim().toLowerCase() : query.type;
      if (quote && Object.values(MarketQuoteType).includes(quote)) {
        activeQuote.value = quote;
      }
      if (category && Object.values(MarketCategoryType).includes(category)) {
        activeCategory.value = category;
      }
      if (type && MarketType.Favorite.toLowerCase() === type) {
        activeType.value = MarketType.Favorite;
      }
      if (type && MarketType.Spot.toLowerCase() === type) {
        activeType.value = MarketType.Spot;
      }
      if (type && [MarketType.Perpetual.toLowerCase(), "perp"].includes(type)) {
        activeType.value = MarketType.Perpetual;
      }
    }
    return (_ctx, _cache) => {
      const _component_PartialsMarketsFilters = __nuxt_component_0;
      const _component_AppSortableHeaderItem = __nuxt_component_1;
      const _component_CommonTableHeader = __nuxt_component_2;
      const _component_PartialsMarketsRow = __nuxt_component_3;
      const _component_BaseIcon = __nuxt_component_4;
      const _component_CommonEmptyList = __nuxt_component_5;
      const _component_CommonTableBody = __nuxt_component_6;
      const _component_PartialsMarketsExpired = __nuxt_component_7;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("markets.title")), 1),
          _createVNode(_component_PartialsMarketsFilters, {
            "active-category": _unref(activeCategory),
            "onUpdate:activeCategory": _cache[0] || (_cache[0] = ($event) => _isRef(activeCategory) ? activeCategory.value = $event : null),
            "active-quote": _unref(activeQuote),
            "onUpdate:activeQuote": _cache[1] || (_cache[1] = ($event) => _isRef(activeQuote) ? activeQuote.value = $event : null),
            "active-type": _unref(activeType),
            "onUpdate:activeType": _cache[2] || (_cache[2] = ($event) => _isRef(activeType) ? activeType.value = $event : null),
            "show-low-volume-markets": _unref(showLowVolumeMarkets),
            "onUpdate:showLowVolumeMarkets": _cache[3] || (_cache[3] = ($event) => _isRef(showLowVolumeMarkets) ? showLowVolumeMarkets.value = $event : null),
            search: _unref(search),
            "onUpdate:search": _cache[4] || (_cache[4] = ($event) => _isRef(search) ? search.value = $event : null)
          }, null, 8, ["active-category", "active-quote", "active-type", "show-low-volume-markets", "search"]),
          _createVNode(_component_CommonTableHeader, { classes: "grid grid-cols-3 md:hidden" }, {
            default: _withCtx(() => [
              _createVNode(_component_AppSortableHeaderItem, {
                class: "select-none",
                value: "market" /* Market */,
                "sort-by": _unref(sortBy),
                ascending: _unref(ascending),
                "onUpdate:sortBy": handleSort,
                "onUpdate:ascending": handleAscending
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("trade.market")), 1)
                ]),
                _: 1
              }, 8, ["value", "sort-by", "ascending"]),
              _createVNode(_component_AppSortableHeaderItem, {
                class: "justify-end col-span-2 select-none",
                value: "change" /* Change */,
                "sort-by": _unref(sortBy),
                ascending: _unref(ascending),
                "onUpdate:sortBy": handleSort,
                "onUpdate:ascending": handleAscending
              }, {
                prefix: _withCtx(() => [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("trade.last_price")) + " / ", 1)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("trade.market_change_24h")), 1)
                ]),
                _: 1
              }, 8, ["value", "sort-by", "ascending"])
            ]),
            _: 1
          }),
          _createVNode(_component_CommonTableHeader, { class: "grid-cols-10 3md:grid-cols-12" }, {
            default: _withCtx(() => [
              _createVNode(_component_AppSortableHeaderItem, {
                class: "col-span-3 select-none",
                value: "market" /* Market */,
                "sort-by": _unref(sortBy),
                ascending: _unref(ascending),
                "onUpdate:sortBy": handleSort,
                "onUpdate:ascending": handleAscending
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("trade.market")), 1)
                ]),
                _: 1
              }, 8, ["value", "sort-by", "ascending"]),
              _createElementVNode("span", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("trade.last_price")), 1)
              ]),
              _createVNode(_component_AppSortableHeaderItem, {
                class: "col-span-2 flex justify-end items-center select-none",
                value: "change" /* Change */,
                "sort-by": _unref(sortBy),
                ascending: _unref(ascending),
                "onUpdate:sortBy": handleSort,
                "onUpdate:ascending": handleAscending
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("trade.market_change_24h")), 1)
                ]),
                _: 1
              }, 8, ["value", "sort-by", "ascending"]),
              _createVNode(_component_AppSortableHeaderItem, {
                class: "col-span-3 flex justify-end items-center select-none",
                value: "volume" /* Volume */,
                "sort-by": _unref(sortBy),
                ascending: _unref(ascending),
                "onUpdate:sortBy": handleSort,
                "onUpdate:ascending": handleAscending
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("trade.volume_24h")), 1)
                ]),
                _: 1
              }, 8, ["value", "sort-by", "ascending"]),
              _hoisted_11
            ]),
            _: 1
          }),
          _createVNode(_component_CommonTableBody, {
            "show-empty": _unref(sortedMarkets).length === 0,
            class: "bg-transparent"
          }, {
            empty: _withCtx(() => [
              _createVNode(_component_CommonEmptyList, {
                class: "min-h-3xs",
                "data-cy": "markets-no-data-table",
                message: _unref(activeType) === _unref(MarketType).Favorite ? _ctx.$t("markets.emptyHeaderFavorites") : _ctx.$t("markets.emptyHeader")
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_BaseIcon, {
                    name: "star-border",
                    class: "text-gray-500 w-8 h-8"
                  })
                ]),
                default: _withCtx(() => [
                  _unref(activeType) === _unref(MarketType).Favorite ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t("markets.emptyDescriptionFavorites")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t("markets.emptyDescription")), 1))
                ]),
                _: 1
              }, 8, ["message"])
            ]),
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedMarkets), ({ market, summary, volumeInUsd }, index) => {
                return _openBlock(), _createBlock(_component_PartialsMarketsRow, {
                  key: `market-row-${market.marketId}-${index}`,
                  market,
                  summary,
                  "volume-in-usd": volumeInUsd
                }, null, 8, ["market", "summary", "volume-in-usd"]);
              }), 128))
            ]),
            _: 1
          }, 8, ["show-empty"])
        ]),
        _unref(recentlyExpiredMarkets).length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("h3", _hoisted_15, _toDisplayString(_ctx.$t("markets.expiredRecently")), 1),
          _createVNode(_component_PartialsMarketsExpired, { markets: _unref(recentlyExpiredMarkets) }, null, 8, ["markets"])
        ])) : _createCommentVNode("", true)
      ]);
    };
  }
});
