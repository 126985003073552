import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Markets/Overview.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Markets/Index.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/HocLoading.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "container" };
import { BigNumberInBase } from "@injectivelabs/utils";
import { QUOTE_DENOMS_GECKO_IDS } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "markets",
  setup(__props) {
    const appStore = useAppStore();
    const spotStore = useSpotStore();
    const tokenStore = useTokenStore();
    const exchangeStore = useExchangeStore();
    const derivativeStore = useDerivativeStore();
    const { $onError } = useNuxtApp();
    const marketsWithSummaryAndVolumeInUsd = computed(
      () => [
        ...spotStore.marketsWithSummary,
        ...derivativeStore.marketsWithSummary,
        ...exchangeStore.upcomingMarketsWithSummary,
        ...exchangeStore.deprecatedMarketsWithSummary
      ].map(({ market, summary }) => {
        const quoteTokenUsdPrice = new BigNumberInBase(
          tokenStore.tokenUsdPrice(market.quoteToken.coinGeckoId)
        );
        return {
          market,
          summary,
          volumeInUsd: quoteTokenUsdPrice.multipliedBy((summary == null ? void 0 : summary.volume) || "0")
        };
      })
    );
    onMounted(() => getQuoteTokenPrice());
    const marketsWithSummariesLoaded = computed(
      () => spotStore.marketsWithSummary.some(({ summary }) => summary) && derivativeStore.marketsWithSummary.some(({ summary }) => summary)
    );
    function getQuoteTokenPrice() {
      Promise.all([
        appStore.pollMarkets(),
        tokenStore.fetchTokenUsdPriceMap(QUOTE_DENOMS_GECKO_IDS)
      ]).catch($onError);
    }
    useIntervalFn(() => getQuoteTokenPrice(), 10 * 1e3);
    return (_ctx, _cache) => {
      const _component_PartialsMarketsOverview = __nuxt_component_0;
      const _component_PartialsMarkets = __nuxt_component_1;
      const _component_AppHocLoading = __nuxt_component_2;
      return _openBlock(), _createBlock(_component_AppHocLoading, {
        "show-loading": !_unref(marketsWithSummariesLoaded),
        class: "h-full"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_PartialsMarketsOverview, { markets: _unref(marketsWithSummaryAndVolumeInUsd) }, null, 8, ["markets"]),
            _createVNode(_component_PartialsMarkets, { markets: _unref(marketsWithSummaryAndVolumeInUsd) }, null, 8, ["markets"])
          ])
        ]),
        _: 1
      }, 8, ["show-loading"]);
    };
  }
});
