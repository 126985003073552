"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "mx-auto mb-3" };
const _hoisted_2 = {
  key: 1,
  src: "/svg/empty-list.svg"
};
const _hoisted_3 = { class: "text-sm" };
export default /* @__PURE__ */ _defineComponent({
  __name: "EmptyList",
  props: {
    message: {
      type: String,
      default: ""
    }
  },
  setup(__props) {
    const slots = useSlots();
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["h-full w-full flex flex-col items-center justify-center py-3", {
          "bg-gray-900": !_ctx.$attrs.class
        }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _unref(slots).icon ? _renderSlot(_ctx.$slots, "icon", { key: 0 }) : (_openBlock(), _createElementBlock("img", _hoisted_2))
        ]),
        _createElementVNode("p", _hoisted_3, _toDisplayString(__props.message), 1),
        _renderSlot(_ctx.$slots, "default")
      ], 2);
    };
  }
});
