"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "space-y-1" };
export default /* @__PURE__ */ _defineComponent({
  __name: "SortableHeaderItem",
  props: {
    ascending: Boolean,
    value: {
      type: String,
      required: true
    },
    sortBy: {
      type: String,
      required: true
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_BaseIcon = _resolveComponent("BaseIcon");
      const _component_BaseSortableHeaderItem = _resolveComponent("BaseSortableHeaderItem");
      return _openBlock(), _createBlock(_component_BaseSortableHeaderItem, _mergeProps({
        ascending: __props.ascending,
        "sort-by": __props.sortBy,
        value: __props.value
      }, _ctx.$attrs), {
        default: _withCtx(({ active }) => [
          _createElementVNode("div", {
            class: _normalizeClass(["flex cursor-pointer items-center gap-1", _ctx.$attrs.class])
          }, [
            _renderSlot(_ctx.$slots, "default"),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_BaseIcon, {
                name: "triangle",
                xs: "",
                class: _normalizeClass(["transition-all rotate-180", [active && __props.ascending ? "text-gray-200" : "text-gray-600"]])
              }, null, 8, ["class"]),
              _createVNode(_component_BaseIcon, {
                name: "triangle",
                class: _normalizeClass([active && !__props.ascending ? "text-gray-200" : "text-gray-600"]),
                xs: ""
              }, null, 8, ["class"])
            ])
          ], 2)
        ]),
        _: 3
      }, 16, ["ascending", "sort-by", "value"]);
    };
  }
});
