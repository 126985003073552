import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex items-center justify-between text-gray-500" };
const _hoisted_2 = { class: "tracking-widest uppercase text-xs" };
const _hoisted_3 = { class: "flex justify-between mt-4" };
const _hoisted_4 = { class: "flex items-center" };
const _hoisted_5 = {
  key: 0,
  class: "w-8 h-8 mr-3"
};
const _hoisted_6 = { class: "flex flex-col" };
const _hoisted_7 = {
  "data-cy": "market-card-ticker-text-content",
  class: "uppercase tracking-widest text-sm font-bold leading-4"
};
const _hoisted_8 = { class: "text-xs text-gray-500 capitalize" };
const _hoisted_9 = { class: "flex items-center justify-start mt-4" };
const _hoisted_10 = {
  class: "text-gray-500 w-full text-sm",
  "data-cy": "market-card-volume-usd-text-content"
};
const _hoisted_11 = { class: "font-mono" };
import {
  ZERO_IN_BASE
} from "@injectivelabs/sdk-ui-ts";
import { BigNumberInBase } from "@injectivelabs/utils";
import { UI_DEFAULT_PRICE_DISPLAY_DECIMALS } from "@/app/utils/constants";
import { getMarketRoute } from "@/app/utils/market";
import { Change } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Card",
  props: {
    market: {
      type: Object,
      required: true
    },
    summary: {
      type: Object,
      required: true
    },
    volumeInUsd: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    var _a;
    const props = __props;
    const marketRoute = getMarketRoute(props.market);
    const lastTradedPrice = computed(() => {
      if (!props.summary || !props.summary.price) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInBase(props.summary.lastPrice || props.summary.price);
    });
    const change = computed(() => {
      if (!props.summary || !props.summary.change) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInBase(props.summary.change);
    });
    const { valueToString: volumeInUsdToFormat } = useBigNumberFormatter(
      computed(() => props.volumeInUsd),
      {
        decimalPlaces: 2
      }
    );
    const { valueToString: lastTradedPriceToFormat } = useBigNumberFormatter(
      lastTradedPrice,
      {
        decimalPlaces: ((_a = props.market) == null ? void 0 : _a.priceDecimals) || UI_DEFAULT_PRICE_DISPLAY_DECIMALS,
        displayAbsoluteDecimalPlace: true
      }
    );
    const { valueToString: changeToFormat } = useBigNumberFormatter(change, {
      decimalPlaces: 2
    });
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      const _component_NuxtLink = __nuxt_component_1;
      return _openBlock(), _createBlock(_component_NuxtLink, {
        to: _unref(marketRoute),
        class: "rounded-lg shadow-card p-4 bg-gray-750 bg-opacity-30 block cursor-pointer"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              __props.market.baseToken ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_CommonTokenIcon, {
                  token: __props.market.baseToken,
                  class: "min-w-full h-auto rounded-full",
                  lg: ""
                }, null, 8, ["token"])
              ])) : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", _hoisted_7, _toDisplayString(__props.market.ticker), 1),
                _createElementVNode("span", _hoisted_8, _toDisplayString(__props.market.baseToken.name), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", {
              class: _normalizeClass(["text-xl tracking-wide font-mono font-semibold flex items-center mr-2", {
                "text-green-500 ": __props.summary.lastPriceChange === _unref(Change).Increase,
                "text-white": __props.summary.lastPriceChange === _unref(Change).NoChange,
                "text-red-500": __props.summary.lastPriceChange === _unref(Change).Decrease
              }]),
              "data-cy": "market-card-last-traded-price-text-content"
            }, _toDisplayString(_unref(lastTradedPriceToFormat)), 3),
            _createElementVNode("span", {
              class: _normalizeClass(["text-sm font-mono", {
                "text-green-500": _unref(change).gt(0),
                "text-white": _unref(change).eq(0),
                "text-red-500": _unref(change).lt(0)
              }]),
              "data-cy": "market-card-change_24h-text-content"
            }, _toDisplayString(_unref(changeToFormat)) + "% ", 3)
          ]),
          _createElementVNode("span", _hoisted_10, [
            _createTextVNode(_toDisplayString(_ctx.$t("markets.vol")) + " ", 1),
            _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(volumeInUsdToFormat)), 1),
            _createTextVNode(" USD ")
          ])
        ]),
        _: 3
      }, 8, ["to"]);
    };
  }
});
