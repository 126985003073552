"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "cursor-pointer" };
export default /* @__PURE__ */ _defineComponent({
  __name: "Button",
  props: {
    value: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_BaseSelectorItem = _resolveComponent("BaseSelectorItem");
      return _openBlock(), _createBlock(_component_BaseSelectorItem, _mergeProps({
        "model-value": __props.modelValue,
        value: __props.value
      }, _ctx.$attrs), {
        default: _withCtx(({ active }) => [
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default", { active })
          ])
        ]),
        _: 3
      }, 16, ["model-value", "value"]);
    };
  }
});
