import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Input.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "flex items-center justify-between" };
const _hoisted_2 = {
  key: 0,
  class: "block text-xs font-semibold text-gray-300 mb-2"
};
const _hoisted_3 = {
  key: 1,
  class: "leading-none"
};
const _hoisted_4 = { class: "flex items-center justify-between no-shadow" };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = {
  key: 1,
  class: "mr-3"
};
const __default__ = {
  inheritAttrs: false
};
export default /* @__PURE__ */ _defineComponent({
  ...__default__,
  __name: "Index",
  props: {
    sm: Boolean,
    valid: Boolean,
    noPadding: Boolean,
    transparentBg: Boolean,
    errors: {
      type: Array,
      default: () => []
    },
    inputClasses: {
      type: String,
      default: ""
    },
    wrapperClasses: {
      type: String,
      default: ""
    }
  },
  setup(__props) {
    const props = __props;
    const slots = useSlots();
    const wrapperClass = computed(() => {
      const result = ["shadow-none"];
      if (!props.transparentBg) {
        result.push("input-wrapper");
      }
      result.push(props.wrapperClasses);
      return result.join(" ");
    });
    const inputClass = computed(() => {
      const result = [];
      if (!props.noPadding) {
        result.push("px-3");
      }
      if (props.sm) {
        result.push("h-8");
      }
      if (props.transparentBg) {
        result.push("input-bg-transparent");
      }
      result.push(props.inputClasses);
      return result.join(" ");
    });
    const classes = computed(() => {
      const result = ["w-full"];
      if (props.valid) {
        result.push("is-valid");
      }
      if (!props.valid && props.errors.length > 0) {
        result.push("is-invalid");
      }
      return result.join(" ");
    });
    return (_ctx, _cache) => {
      const _component_BaseInput = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["w-full input-wrap", _unref(classes)])
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_1, [
            _ctx.$attrs.label ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.$attrs.label || ""), 1)) : _createCommentVNode("", true),
            _unref(slots).context ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "context")
            ])) : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["relative", _unref(wrapperClass)])
          }, [
            _createElementVNode("div", _hoisted_4, [
              _unref(slots).prefix ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "prefix")
              ])) : _createCommentVNode("", true),
              _createVNode(_component_BaseInput, _mergeProps(_ctx.$attrs, { class: _unref(inputClass) }), null, 16, ["class"]),
              _unref(slots).addon ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "addon")
              ])) : _createCommentVNode("", true)
            ])
          ], 2)
        ])
      ], 2);
    };
  }
});
