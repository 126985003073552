"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    markets: {
      type: Array,
      required: true
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_PartialsMarketsExpiredRow = _resolveComponent("PartialsMarketsExpiredRow");
      const _component_CommonTableBody = _resolveComponent("CommonTableBody");
      return _openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_CommonTableBody, {
          light: "",
          "show-empty": __props.markets.length === 0
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.markets, (market, index) => {
              return _openBlock(), _createBlock(_component_PartialsMarketsExpiredRow, {
                key: `market-expired-row-${index}`,
                market
              }, null, 8, ["market"]);
            }), 128))
          ]),
          _: 1
        }, 8, ["show-empty"])
      ]);
    };
  }
});
