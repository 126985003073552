import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Dropdown.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderSlot as _renderSlot, unref as _unref, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "bg-gray-800 rounded-lg p-2 flex flex-col" };
const _hoisted_2 = ["onClick"];
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    options: {
      type: Array,
      required: true
    },
    modelValue: {
      type: String,
      default: ""
    },
    wrapperClass: {
      type: String,
      default: ""
    }
  },
  emits: ["update:modelValue"],
  setup(__props, { emit }) {
    const props = __props;
    const uuid = Math.random();
    const selectedOption = computed(
      () => props.options.find((option) => option.value === props.modelValue)
    );
    function handleSelect(option) {
      emit("update:modelValue", option.value);
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_BaseDropdown = __nuxt_component_1;
      return _openBlock(), _createBlock(_component_BaseDropdown, {
        "popper-class": "selector min-w-40",
        placement: "bottom-end",
        flip: false
      }, {
        default: _withCtx(({ shown }) => [
          _createElementVNode("div", {
            class: _normalizeClass(["flex items-center gap-2", __props.wrapperClass])
          }, [
            _renderSlot(_ctx.$slots, "prefix"),
            _renderSlot(_ctx.$slots, "default", { selected: _unref(selectedOption) }),
            _renderSlot(_ctx.$slots, "icon", { shown }, () => [
              _createVNode(_component_BaseIcon, {
                name: "chevron-down",
                class: _normalizeClass(["h-3 w-3 min-w-3 fill-current", {
                  "ease-in-out duration-300": shown,
                  "rotate-180": shown,
                  "rotate-0": !shown
                }])
              }, null, 8, ["class"])
            ])
          ], 2)
        ]),
        content: _withCtx(({ close }) => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option, index) => {
              return _openBlock(), _createElementBlock("div", {
                key: `${_unref(uuid)}-selector-${index}`,
                class: _normalizeClass(["flex items-center px-2 py-1 cursor-pointer rounded", [
                  option.value === __props.modelValue ? "text-blue-500 hover:text-blue-900 hover:bg-blue-500" : "text-white hover:bg-blue-500 hover:text-blue-900"
                ]]),
                onClick: () => {
                  handleSelect(option);
                  close();
                }
              }, [
                _renderSlot(_ctx.$slots, "option", { option })
              ], 10, _hoisted_2);
            }), 128))
          ])
        ]),
        _: 3
      });
    };
  }
});
