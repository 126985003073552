import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, isRef as _isRef, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-06273d90"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "flex items-center justify-start" };
const _hoisted_2 = { class: "checkbox-wrapper mr-2" };
const _hoisted_3 = ["id", "disabled"];
const _hoisted_4 = ["for", "data-cy"];
const _hoisted_5 = ["for"];
export default /* @__PURE__ */ _defineComponent({
  __name: "Checkbox",
  props: {
    disabled: Boolean,
    modelValue: Boolean,
    tooltip: {
      type: String,
      default: ""
    },
    dataCy: {
      type: String,
      default: "unknown-id"
    }
  },
  emits: ["update:modelValue"],
  setup(__props, { emit }) {
    const props = __props;
    const uid = window.crypto.getRandomValues(new Uint32Array(1))[0].toString();
    const checked = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit("update:modelValue", value);
      }
    });
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("input", {
            id: _unref(uid),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(checked) ? checked.value = $event : null),
            disabled: __props.disabled,
            class: "checkbox",
            type: "checkbox"
          }, null, 8, _hoisted_3), [
            [_vModelCheckbox, _unref(checked)]
          ]),
          _createElementVNode("label", {
            for: _unref(uid),
            "data-cy": __props.dataCy,
            class: _normalizeClass(["top-0 left-0 flex items-center justify-center absolute", {
              "cursor-pointer": !__props.disabled
            }])
          }, [
            _createVNode(_component_BaseIcon, {
              name: "check",
              class: "w-2 h-2 text-gray-750 checkmark"
            }),
            _createVNode(_component_BaseIcon, {
              name: "minus",
              class: "w-2 h-2 text-gray-500 minus"
            })
          ], 10, _hoisted_4)
        ]),
        _createElementVNode("label", {
          for: _unref(uid),
          class: _normalizeClass(["select-none text-xs whitespace-nowrap", {
            "text-gray-500": __props.disabled,
            "text-white cursor-pointer": !__props.disabled
          }])
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 10, _hoisted_5)
      ]);
    };
  }
});
