import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/TabMenu/Item.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Select/Button.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/Search.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Common/TabMenu/Index.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Partials/Markets/Filters/CategorySelector.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/App/Select/Index.vue";
import { default as __nuxt_component_7 } from "/opt/build/repo/components/App/Checkbox.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, isRef as _isRef, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "flex items-center"
};
const _hoisted_2 = { key: 1 };
const _hoisted_3 = { key: 2 };
const _hoisted_4 = { key: 3 };
const _hoisted_5 = { class: "flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-0 justify-between mt-6 mb-2 px-3 sm:px-0" };
const _hoisted_6 = { class: "flex items-center gap-2" };
const _hoisted_7 = { class: "flex items-center" };
const _hoisted_8 = { class: "text-xs text-gray-300 uppercase" };
const _hoisted_9 = {
  key: 0,
  class: "text-xs text-blue-500 uppercase"
};
const _hoisted_10 = { class: "text-xs uppercase text-white" };
import { MarketType } from "@injectivelabs/sdk-ui-ts";
import { MarketCategoryType, MarketQuoteType } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    showLowVolumeMarkets: Boolean,
    search: {
      type: String,
      required: true
    },
    activeType: {
      type: String,
      required: true
    },
    activeQuote: {
      type: String,
      required: true
    },
    activeCategory: {
      type: String,
      required: true
    }
  },
  emits: ["update:activeCategory", "update:search", "update:activeQuote", "update:activeType", "update:showLowVolumeMarkets"],
  setup(__props, { emit }) {
    const props = __props;
    const route = useRoute();
    const router = useRouter();
    const FilterList = {
      [MarketType.Favorite]: MarketType.Favorite,
      All: "",
      [MarketType.Spot]: MarketType.Spot,
      [MarketType.Derivative]: MarketType.Derivative
    };
    const marketCategoryTypes = Object.entries(MarketCategoryType).map(
      ([key, value]) => ({
        key: `market-category-type-${value}`,
        label: key,
        type: MarketCategoryType[key]
      })
    );
    const quoteOptions = Object.entries(MarketQuoteType).map(([key, value]) => ({
      display: key,
      value
    }));
    const activeQuoteValue = computed({
      get: () => props.activeQuote,
      set: (value) => {
        emit("update:activeQuote", value);
      }
    });
    const showLowVolumeMarketsValue = computed({
      get: () => props.showLowVolumeMarkets,
      set: (type) => {
        emit("update:showLowVolumeMarkets", type);
      }
    });
    const activeFilterType = computed({
      get: () => props.activeType,
      set: (type) => {
        emit("update:activeType", type);
        if (type === props.activeType) {
          return;
        }
        if (!type || type === "") {
          clearRouteQueryParam("type");
        } else {
          fillRouteQueryParams({ type: type.toLowerCase() });
        }
      }
    });
    function handleCategoryChange(category) {
      emit("update:activeCategory", category);
      if (category === props.activeCategory) {
        return;
      }
      if (!category || category === MarketCategoryType.All) {
        clearRouteQueryParam("category");
      } else {
        fillRouteQueryParams({ category: category.toLowerCase() });
      }
    }
    function handleSearchedEvent(search) {
      emit("update:search", search);
    }
    function handleQuoteChange(quote) {
      if (quote === props.activeQuote) {
        return;
      }
      if (!quote || quote === MarketQuoteType.All) {
        clearRouteQueryParam("quote");
      } else {
        fillRouteQueryParams({ quote: quote.toLowerCase() });
      }
    }
    function clearRouteQueryParam(key) {
      const { query } = route;
      const queryClone = { ...query };
      delete queryClone[key];
      router.replace({ query: queryClone });
    }
    function fillRouteQueryParams(params) {
      router.replace({
        path: route.path,
        query: {
          ...route.query,
          ...params
        }
      });
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_CommonTabMenuItem = __nuxt_component_1;
      const _component_AppSelectButton = __nuxt_component_2;
      const _component_AppSearch = __nuxt_component_3;
      const _component_CommonTabMenu = __nuxt_component_4;
      const _component_PartialsMarketsFiltersCategorySelector = __nuxt_component_5;
      const _component_AppSelect = __nuxt_component_6;
      const _component_AppCheckbox = __nuxt_component_7;
      return _openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_CommonTabMenu, null, {
          actions: _withCtx(() => [
            _createVNode(_component_AppSearch, {
              name: "search",
              class: "sm:w-auto md:w-3xs",
              "input-classes": "placeholder-white",
              dense: "",
              "transparent-bg": "",
              "data-cy": "markets-search-input",
              placeholder: _ctx.$t("trade.search_markets"),
              "show-prefix": "",
              "model-value": __props.search,
              "onUpdate:modelValue": handleSearchedEvent
            }, null, 8, ["placeholder", "model-value"])
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(FilterList), (filterType) => {
              return _openBlock(), _createBlock(_component_AppSelectButton, {
                key: `market-tabs-${filterType}`,
                modelValue: _unref(activeFilterType),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(activeFilterType) ? activeFilterType.value = $event : null),
                value: filterType
              }, {
                default: _withCtx(({ active }) => [
                  _createVNode(_component_CommonTabMenuItem, { active }, {
                    default: _withCtx(() => [
                      filterType === FilterList[_unref(MarketType).Favorite] ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                        _createVNode(_component_BaseIcon, {
                          name: "star-border",
                          class: "mr-1"
                        }),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t("trade.favorites")), 1)
                      ])) : filterType === FilterList.All ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("trade.allMarkets")), 1)) : filterType === FilterList[_unref(MarketType).Spot] ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("trade.spots")), 1)) : filterType === FilterList[_unref(MarketType).Derivative] ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t("trade.futures")), 1)) : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["active"])
                ]),
                _: 2
              }, 1032, ["modelValue", "value"]);
            }), 128))
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(marketCategoryTypes), (marketCategoryType) => {
              return _openBlock(), _createBlock(_component_PartialsMarketsFiltersCategorySelector, {
                key: marketCategoryType.key,
                type: marketCategoryType.type,
                active: __props.activeCategory === marketCategoryType.type,
                "data-cy": `market-category-${marketCategoryType.key}-button`,
                onClick: handleCategoryChange
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(marketCategoryType.label), 1)
                ]),
                _: 2
              }, 1032, ["type", "active", "data-cy"]);
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_AppSelect, {
              modelValue: _unref(activeQuoteValue),
              "onUpdate:modelValue": [
                _cache[1] || (_cache[1] = ($event) => _isRef(activeQuoteValue) ? activeQuoteValue.value = $event : null),
                handleQuoteChange
              ],
              options: _unref(quoteOptions),
              class: "self-end"
            }, {
              prefix: _withCtx(() => [
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("markets.quote")), 1)
              ]),
              default: _withCtx(({ selected }) => [
                selected ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(selected.display), 1)) : _createCommentVNode("", true)
              ]),
              option: _withCtx(({ option }) => [
                _createElementVNode("span", _hoisted_10, _toDisplayString(option.display), 1)
              ]),
              _: 1
            }, 8, ["modelValue", "options"]),
            _createVNode(_component_AppCheckbox, {
              modelValue: _unref(showLowVolumeMarketsValue),
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => _isRef(showLowVolumeMarketsValue) ? showLowVolumeMarketsValue.value = $event : null),
              class: "ml-4",
              sm: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("markets.showLowVol")), 1)
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ])
      ]);
    };
  }
});
