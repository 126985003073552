import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Common/Market/Airdrop.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = ["data-cy"];
const _hoisted_2 = { class: "text-sm col-span-2 sm:col-span-3 flex items-center gap-4" };
const _hoisted_3 = { class: "flex flex-col w-full overflow-hidden" };
const _hoisted_4 = ["title"];
const _hoisted_5 = { class: "text-gray-500 text-xs hidden md:block" };
const _hoisted_6 = { class: "text-gray-500 text-xs sm:hidden tracking-wide mt-1" };
const _hoisted_7 = { class: "sm:hidden flex flex-col items-end font-mono" };
const _hoisted_8 = { class: "flex items-center" };
const _hoisted_9 = {
  key: 1,
  class: "text-gray-400"
};
const _hoisted_10 = {
  key: 0,
  class: "mt-1"
};
const _hoisted_11 = {
  class: "hidden font-mono sm:flex items-center justify-end col-span-2",
  "data-cy": "markets-last-traded-price-table-data"
};
const _hoisted_12 = {
  key: 1,
  class: "text-gray-400"
};
const _hoisted_13 = { class: "hidden sm:block font-mono text-right col-span-2 text-sm" };
const _hoisted_14 = {
  key: 1,
  class: "text-gray-400"
};
const _hoisted_15 = { class: "hidden sm:block font-mono col-span-3" };
const _hoisted_16 = {
  key: 0,
  class: "flex flex-col items-end"
};
const _hoisted_17 = {
  "data-cy": "markets-volume-usd-table-data",
  class: "mb-1"
};
const _hoisted_18 = {
  class: "text-xs text-gray-500",
  "data-cy": "markets-volume-quote-asset-table-data"
};
const _hoisted_19 = {
  key: 1,
  class: "text-gray-400"
};
const _hoisted_20 = { class: "hidden 3md:flex col-span-2 items-center justify-end" };
const _hoisted_21 = ["onClick"];
import { BigNumberInBase } from "@injectivelabs/utils";
import {
  ZERO_IN_BASE
} from "@injectivelabs/sdk-ui-ts";
import {
  UI_DEFAULT_PRICE_DISPLAY_DECIMALS,
  UI_DEFAULT_DISPLAY_DECIMALS,
  UI_MINIMAL_ABBREVIATION_FLOOR
} from "@/app/utils/constants";
import { Change, TradeClickOrigin } from "@/types";
import { getMarketRoute } from "@/app/utils/market";
import { amplitudeTracker } from "@/app/providers/AmplitudeTracker";
import { stableCoinDenoms } from "@/app/data/token";
export default /* @__PURE__ */ _defineComponent({
  __name: "Row",
  props: {
    market: {
      type: Object,
      required: true
    },
    summary: {
      type: Object,
      default: void 0
    },
    volumeInUsd: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    var _a;
    const props = __props;
    const appStore = useAppStore();
    const marketRoute = getMarketRoute(props.market);
    const lastTradedPrice = computed(() => {
      if (!props.summary || !props.summary.price) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInBase(props.summary.lastPrice || props.summary.price);
    });
    const isFavorite = computed(
      () => appStore.favoriteMarkets.includes(props.market.marketId)
    );
    const quoteVolume = computed(() => {
      if (!props.summary || !props.summary.volume) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInBase(props.summary.volume);
    });
    const volumeInUsdToFormat = computed(
      () => props.volumeInUsd.toFormat(2, BigNumberInBase.ROUND_DOWN)
    );
    const formatterOptions = computed(() => {
      return stableCoinDenoms.includes(props.market.quoteToken.symbol) ? {
        decimalPlaces: 0,
        abbreviationFloor: UI_MINIMAL_ABBREVIATION_FLOOR
      } : {
        abbreviationFloor: void 0,
        decimalPlaces: UI_DEFAULT_PRICE_DISPLAY_DECIMALS
      };
    });
    const change = computed(() => {
      if (!props.summary || !props.summary.change) {
        return ZERO_IN_BASE;
      }
      return new BigNumberInBase(props.summary.change);
    });
    const lastPriceChange = computed(() => {
      if (!props.summary) {
        return Change.NoChange;
      }
      if (!props.summary.lastPriceChange) {
        return Change.NoChange;
      }
      return props.summary.lastPriceChange;
    });
    const { valueToString: changeToFormat } = useBigNumberFormatter(change, {
      decimalPlaces: 2,
      minimalDecimalPlaces: 4
    });
    const { valueToString: lastTradedPriceToFormat } = useBigNumberFormatter(
      lastTradedPrice,
      {
        decimalPlaces: ((_a = props.market) == null ? void 0 : _a.priceDecimals) || UI_DEFAULT_PRICE_DISPLAY_DECIMALS,
        displayAbsoluteDecimalPlace: true
      }
    );
    const { valueToString: quoteVolumeToFormat } = useBigNumberFormatter(
      quoteVolume,
      {
        decimalPlaces: UI_DEFAULT_DISPLAY_DECIMALS
      }
    );
    const { valueToString: abbreviatedVolumeInUsdToFormat } = useBigNumberFormatter(
      computed(() => props.volumeInUsd),
      formatterOptions.value
    );
    function updateWatchList() {
      appStore.updateFavoriteMarkets(props.market.marketId);
    }
    function handleTradeClickedTrack() {
      amplitudeTracker.submitTradeClickedTrackEvent({
        market: props.market.slug,
        marketType: props.market.subType,
        origin: TradeClickOrigin.MarketsPage
      });
    }
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_CommonTokenIcon = __nuxt_component_1;
      const _component_PartialsCommonMarketAirdrop = __nuxt_component_2;
      const _component_NuxtLink = __nuxt_component_3;
      return _openBlock(), _createElementBlock("div", {
        class: "grid grid-cols-3 sm:grid-cols-10 3md:grid-cols-12 text-gray-200 gap-4 text-sm px-4 py-5 mb-1 items-center",
        "data-cy": `markets-table-row-${__props.market.ticker}`
      }, [
        _createElementVNode("span", _hoisted_2, [
          _createElementVNode("div", {
            class: "3md:hidden text-blue-500 mr-3 cursor-pointer",
            "data-cy": "markets-favourite-button",
            onClick: updateWatchList
          }, [
            _unref(isFavorite) ? (_openBlock(), _createBlock(_component_BaseIcon, {
              key: 0,
              name: "star",
              class: "min-w-6 w-6 h-6"
            })) : (_openBlock(), _createBlock(_component_BaseIcon, {
              key: 1,
              name: "star-border",
              class: "min-w-6 w-6 h-6"
            }))
          ]),
          _createVNode(_component_NuxtLink, {
            to: _unref(marketRoute),
            class: "w-full cursor-pointer"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "cursor-pointer flex items-center",
                onClick: handleTradeClickedTrack
              }, [
                __props.market.baseToken ? (_openBlock(), _createBlock(_component_CommonTokenIcon, {
                  key: 0,
                  token: __props.market.baseToken,
                  class: "mr-3 hidden 3md:block"
                }, null, 8, ["token"])) : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", {
                    class: "tracking-wider font-bold mb-1 overflow-hidden overflow-ellipsis items-start",
                    title: __props.market.ticker,
                    "data-cy": "markets-ticker-name-table-data"
                  }, _toDisplayString(__props.market.ticker), 9, _hoisted_4),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(__props.market.baseToken.name), 1),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("markets.vol")) + " " + _toDisplayString(_unref(abbreviatedVolumeInUsdToFormat)) + " USD ", 1)
                ]),
                _createVNode(_component_PartialsCommonMarketAirdrop, {
                  market: __props.market,
                  class: "visible sm:invisible lg:visible ml-auto"
                }, null, 8, ["market"])
              ])
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            !_unref(lastTradedPrice).isNaN() ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["", {
                "text-green-500": _unref(lastPriceChange) === _unref(Change).Increase,
                "text-white": _unref(lastPriceChange) === _unref(Change).NoChange,
                "text-red-500": _unref(lastPriceChange) === _unref(Change).Decrease
              }])
            }, _toDisplayString(_unref(lastTradedPriceToFormat)), 3)) : (_openBlock(), _createElementBlock("span", _hoisted_9, "\u2014"))
          ]),
          !_unref(change).isNaN() ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("span", {
              class: _normalizeClass(_unref(change).gte(0) ? "text-green-500" : "text-red-500")
            }, _toDisplayString(_unref(changeToFormat)) + "% ", 3)
          ])) : _createCommentVNode("", true)
        ]),
        _createElementVNode("span", _hoisted_11, [
          !_unref(lastTradedPrice).isNaN() ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass({
              "text-green-500": _unref(lastPriceChange) === _unref(Change).Increase,
              "text-white": _unref(lastPriceChange) === _unref(Change).NoChange,
              "text-red-500": _unref(lastPriceChange) === _unref(Change).Decrease
            })
          }, _toDisplayString(_unref(lastTradedPriceToFormat)), 3)) : (_openBlock(), _createElementBlock("span", _hoisted_12, "\u2014"))
        ]),
        _createElementVNode("span", _hoisted_13, [
          !_unref(change).isNaN() ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            "data-cy": "markets-change_24h-table-data",
            class: _normalizeClass({
              "text-green-500": _unref(change).gt(0),
              "text-white": _unref(change).eq(0),
              "text-red-500": _unref(change).lt(0)
            })
          }, _toDisplayString(_unref(changeToFormat)) + "% ", 3)) : (_openBlock(), _createElementBlock("span", _hoisted_14, "\u2014"))
        ]),
        _createElementVNode("span", _hoisted_15, [
          !_unref(quoteVolume).isNaN() ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(volumeInUsdToFormat)) + " USD ", 1),
            _createElementVNode("span", _hoisted_18, [
              _createTextVNode(_toDisplayString(_unref(quoteVolumeToFormat)) + " ", 1),
              _createElementVNode("span", null, _toDisplayString(__props.market.quoteToken.symbol), 1)
            ])
          ])) : (_openBlock(), _createElementBlock("span", _hoisted_19, "\u2014"))
        ]),
        _createElementVNode("span", _hoisted_20, [
          _createVNode(_component_NuxtLink, {
            to: _unref(marketRoute),
            class: "text-blue-500 hover:text-blue-600 cursor-pointer",
            "data-cy": "markets-trade-link"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                onClick: _withModifiers(handleTradeClickedTrack, ["stop"])
              }, _toDisplayString(_ctx.$t("trade.trade")), 9, _hoisted_21)
            ]),
            _: 1
          }, 8, ["to"]),
          _createElementVNode("div", {
            class: "text-blue-500 w-6 h-6 flex items-center justify-center rounded-full ml-6 cursor-pointer hover:bg-blue-500 hover:bg-opacity-10",
            "data-cy": "markets-favorite-button",
            onClick: updateWatchList
          }, [
            _unref(isFavorite) ? (_openBlock(), _createBlock(_component_BaseIcon, {
              key: 0,
              name: "star",
              class: "min-w-5 w-5 h-5",
              "data-cy": "markets-is-favorite-icon"
            })) : (_openBlock(), _createBlock(_component_BaseIcon, {
              key: 1,
              name: "star-border",
              class: "min-w-5 w-5 h-5",
              "data-cy": "markets-is-not-favorite-icon"
            }))
          ])
        ])
      ], 8, _hoisted_1);
    };
  }
});
