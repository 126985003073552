import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between flex-wrap border-b border-b-gray-600" }
const _hoisted_2 = { class: "flex items-center gap-2 3md:gap-4 overflow-x-auto justify-between xs:justify-start w-full xs:w-auto hide-scrollbar mb-[-2px]" }
const _hoisted_3 = { class: "mt-4 sm:mt-2 md:mt-0 flex justify-start items-start sm:items-center flex-col sm:flex-row" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "actions")
    ])
  ]))
}